import React, { useState } from 'react';
import { Menu, Layout, Tabs, Table, Space, Tag } from 'antd';
import type { MenuProps } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import './index.less';
interface DataType {
  key: string;
  name: string;
  age: number;
  address: string;
  tags: string[];
}
const columns: ColumnsType<DataType> = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    // render: (text) => <a>{text}</a>,
  },
  {
    title: 'Age',
    dataIndex: 'age',
    key: 'age',
  },
  {
    title: 'Address',
    dataIndex: 'address',
    key: 'address',
  },
  {
    title: 'Tags',
    key: 'tags',
    dataIndex: 'tags',
    render: (_, { tags }) => (
      <>
        {tags.map((tag) => {
          let color = tag.length > 5 ? 'geekblue' : 'green';
          if (tag === 'loser') {
            color = 'volcano';
          }
          return (
            <Tag color={color} key={tag}>
              {tag.toUpperCase()}
            </Tag>
          );
        })}
      </>
    ),
  },
  {
    title: 'Action',
    key: 'action',
    render: (_, record) => (
      <Space size="middle">
        <a>Invite {record.name}</a>
        <a>Delete</a>
      </Space>
    ),
  },
];

const data: DataType[] = [
  {
    key: '1',
    name: 'John Brown',
    age: 32,
    address: 'New York No. 1 Lake Park',
    tags: ['nice', 'developer'],
  },
  {
    key: '2',
    name: 'Jim Green',
    age: 42,
    address: 'London No. 1 Lake Park',
    tags: ['loser'],
  },
  {
    key: '3',
    name: 'Joe Black',
    age: 32,
    address: 'Sidney No. 1 Lake Park',
    tags: ['cool', 'teacher'],
  },
];

const items: MenuProps['items'] = [
  {
    label: '配置设置',
    key: 'config',
  },
  {
    label: '新增配置',
    key: 'app',
  },
];
const cls = 'weapp-emonitor-html';
const { Header, Footer, Sider, Content } = Layout;

const ConfigList: React.FC = () => {
  return (
    <div className={`${cls}-table`}>
      <Table columns={columns} dataSource={data} />
    </div>
  );
};

const ConfigPage: React.FC = () => {
  const [current, setCurrent] = useState('1');

  const onChange = (key: string) => {
    console.log(key);
    setCurrent(key);
  };

  return (
    <Layout className={`${cls}-wrapper`}>
      {/* <Header className={`${cls}-header`}>
        <Menu onClick={onClick} selectedKeys={[current]} mode="horizontal" items={items} />
      </Header> */}
      <Content className={`${cls}-content`}>
        <div className={'box'}>
          11ssjkl萨达
        </div>
        <Tabs
          // defaultActiveKey="1"
          activeKey={current}
          onChange={onChange}
          items={[
            {
              label: `配置设置`,
              key: '1',
              children: <ConfigList></ConfigList>,
            },
            {
              label: `配置信息`,
              key: '2',
            },
            {
              label: `Tab 3`,
              key: '3',
            },
          ]}
        />
      </Content>
    </Layout>
  );
};

export default ConfigPage;
