import React, { lazy, useState } from 'react';
import { RouteObject, Navigate } from 'react-router-dom';
import ListAdd from '@/pages/list/cmp/ListAdd';
import NoFound from '@/pages/404/index';
import PhotoAdd from '@/pages/photo/cmp/PhotoAdd';
import PhotoAlter from '@/pages/photo/cmp/PhotoAlter';
import PhotoDelete from '@/pages/photo/cmp/PhotoDelete';
import { lazyLoad } from '@/component/utils';
import { Forgot, StandardLogin, Register } from '@/pages/login/entry';

import { needToken } from '@/constants';
import { managerRoutes } from '@/pages/backstage';
import { mobileRoutes } from '@/pages/mobile';
import HomeTest from '@/pages/test';


const LoginPage = lazyLoad({
  loader: () => import('@/pages/login/index'),
});

const MyList = lazyLoad({
  loader: () => import(/* webpackChunkName: 'list' */ '@/pages/list'),
  needToken,
});

const PcMain = lazyLoad({
  loader: () => import(/* webpackChunkName: 'list' */ './pcMain'),
  // needToken
});

const MyPhoto = lazyLoad({
  loader: () => import(/* webpackChunkName: 'list' */ '@/pages/photo'),
  needToken,
});

const Message = lazyLoad({
  loader: () => import(/* webpackChunkName: 'list' */ '@/pages/message'),
  needToken,
});

const About = lazyLoad({
  loader: () => import(/* webpackChunkName: 'list' */ '@/pages/aboutPage'),
  needToken,
});

const Manager = lazyLoad({
  loader: () => import(/* webpackChunkName: 'list' */ '@/pages/manager'),
  needToken,
});

const TestPage = lazyLoad({
  loader: () => import(/* webpackChunkName: 'list' */ '@/pages/test'),
});

const routes: RouteObject[] = [
  {
    path: '/',
    element: <PcMain />,
  },
  {
    path: '/login',
    element: <LoginPage />,
    // element: <Navigate to="/home" />,
    children: [
      {
        path: 'index',
        element: <StandardLogin />,
      },
      {
        path: 'forgot',
        element: <Forgot />,
      },
      {
        path: 'register',
        element: <Register />,
      },
    ],
  },
  {
    path: 'home',
    element: (
      <>
        <HomeTest></HomeTest>
      </>
    ),
  },
  {
    path: '/list',
    element: <MyList />,
    children: [
      {
        path: 'add',
        element: <ListAdd />,
      },
    ],
  },
  {
    path: '/photo',
    element: <MyPhoto />,
    children: [
      {
        path: '',
        element: <Navigate to="add" />,
      },
      {
        path: 'add',
        element: <PhotoAdd />,
      },
      {
        path: 'alter/:id?',
        element: <PhotoAlter />,
      },
      {
        path: 'delete',
        element: <PhotoDelete />,
      },
    ],
  },
  {
    path: '/message',
    element: <Message />,
  },
  {
    path: '/about',
    element: <About />,
  },
  {
    path: '/manager',
    element: <Manager />,
  },
  {
    path: '/test',
    element: <TestPage></TestPage>,
  },
  ...managerRoutes,
  ...mobileRoutes,
  {
    path: '*',
    element: <NoFound />,
  },
];

export default routes;
